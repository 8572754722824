import React from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import WhyStudio from "../Reusable/WhyStudio";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import CallToAction from "../Reusable/CallToAction";
import {Helmet} from "react-helmet";

const VideoProduction = () => {
  const services = [
    {
      title: "Corporate Video Production",
      alt:"corporate video production",
      image: "/Images/video-production/corporate-video-production.png",
      description:
        "Whether you're looking to create impactful corporate profiles, engaging training videos or compelling promotional content, our team excels in translating your corporate message into visually compelling stories.",
     
    },
    {
      title: "Commercial and Advertising",
      alt:"commercial and advertising",
      image: "/Images/video-production/commercial-and-advertising.png",
      description:
        "From concept development to final delivery, we excel in creating attention-grabbing commercials and advertisements that resonate with your target audience and enhance brand visibility.",
      
    },
    {
      title: "Event Coverage",
      alt:"event coverage",
      image: "/Images/video-production/event-coverage.png",
      description:
        "Capturing the essence of your events with finesse, our event coverage services ensure every moment is immortalized beautifully, making your events unforgettable.",
      
    },
    {
      title: "Documentary Filmmaking",
      alt:"documentary filmmaking",
      image: "/Images/video-production/documentary-filmmaking.png",
      description:
        "We specialize in producing thought-provoking documentaries that highlight significant stories and subjects, bringing authenticity and depth to every frame.",
     
    },
    {
      title: "Music Videos",
      alt:"music videos",
      image: "/Images/video-production/music-videos.png",
      description:
        "Studio Final Take is your go-to destination for creating visually stunning music videos that complement your music's artistic expression, ensuring it resonates with your audience.",
     
    },
  ];

  const points = [
    {
      title: "Creativity and Innovation",
      description:
        "We thrive on creativity and innovation, constantly pushing boundaries to deliver fresh, original concepts that stand out in the digital landscape.",
    },
    {
      title: "Experienced Team",
      description:
        "Our team comprises seasoned professionals with extensive experience in video production, ensuring flawless execution from pre-production to post-production.",
    },
    {
      title: "Cutting-edge Technology",
      description:
        "Equipped with the latest cameras, editing software and studio facilities, we deliver high-definition videos that meet industry standards and exceed client expectations.",
    },
    {
      title: "Client-Centric Approach",
      description:
        "We prioritize client satisfaction, working closely with you throughout the production process to ensure your vision is brought to life seamlessly.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          Best Video Production Services in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take offers best video production services in Delhi, ensuring exceptional quality and creativity."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/video-production-company"
        />
        <meta
          property="og:title"
          content="Best Video Production Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take offers best video production services in Delhi, ensuring exceptional quality and creativity."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/video-production-company"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <ReusableBanner
        headone="Enhance your audio"
        headtwo="mesmerize your viewers!"
         banner="/Images/banner/video-production-company-banner.jpg"
        para="Studio Final Take offers premier video production services in Delhi, catering to diverse creative needs with expertise and innovation. Whether you're planning a corporate video, commercial, music video or film production, our team ensures top-notch quality from concept to completion. With state-of-the-art equipment and skilled professionals, we deliver captivating visuals that resonate with your audience. Trust Studio Final Take for the best video production services in Delhi, where each project is crafted with precision, creativity and a commitment to exceeding client expectations."
      />
      <WhyStudio
        img_two="/Images/video-production-company-service-two.jpg"
        alt_one="video production company service "
        alt_two="video production company service"
        img_circle="/Images/testnew.png"
        head_sec="Best Video Production Services in Delhi"
        para_sec="At Studio Final Take, we specialize in delivering top-tier video production services tailored to meet your creative vision and business objectives. Located in the heart of Delhi, our studio is equipped with state-of-the-art facilities and a passionate team dedicated to producing captivating visual content."
        img_one="/Images/video-production-company-service-one.jpg"
      />

      <ServiceSection
        heading="Studio Final Take offers a comprehensive range of video production services designed to cater to diverse client needs:"
        services={services}
      />

      <WhyUs
        heading="Here’s what sets us apart as the best video production service provider in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      <CallToAction />
      {/* <CommingSoon /> */}
    </div>
  );
};

export default VideoProduction;
