import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Modal,
  Form,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import Lelo from "./logo-lelotrip.png";
// import "./MainNav.css";
// import "./LoginModal.css";
import "./Booking.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';

import { Autoplay, Navigation } from "swiper/modules";
import { toast } from "react-toastify";
const Booking = ({ active, setShow, setActive, handleShow }) => {
  const[loading,setLoading]=useState(false)
  const [formData, setFormData] = useState({
    artistName: "",
    mobile: "",
    email: "",
    date: "",
    time: "",
    requirements: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post("https://studiofinaltake.com/api/send-booking-email", formData);
      if (response.status === 200) {
        setLoading(false)
        // alert("Booking request submitted successfully!");
        setFormData({
          artistName: "",
          mobile: "",
          email: "",
          date: "",
          time: "",
          requirements: "",
        });
        setActive(false)
        toast.success("Booking request submitted successfully!");
      } else {
        toast.error("Failed to submit booking request.");
        setLoading(false)
      }
    } catch (error) {
      // alert("Error: " + error.message);
      setLoading(false)
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <Modal
      show={active}
      onHide={setActive}
      className="p-fixed l-0 r-0 b-0 t-0 flex flex-center flex-middle z-70 signup_modal"
      style={{ backgroundColor: "#000000a1", zIndex: 10000 }}
      size="lg"
    >
      <Modal.Body>
        <div
          className="p-fixed l-0 r-0 b-0 t-0 flex flex-center flex-middle z-70"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "10000" }}
        >
          <div className="p-relative">
            <div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div
                  className="o-hidden flex-column brLogin-4 slick-dots slider_part"
                  style={{ width: "450px", height: "515px" }}
                >
                  <Swiper
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                  
                    
                      <img src="/Images/music-popup.jpg" alt="studio final take music offer" style={{height:'515px'}}
                      className="image_sign_up_modal"/>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div
                  className="bg-white o-hidden d-flex flex-column brLoginNew-4 signup_form"
                  style={{ width: "450px", height: "515px" }}
                >
                  <div className=" d-flex flex-1 flex-column">
                    <div className="pt-6 pb-6 flex flex-top flex-between booking_slot_form_studio">
                     <div className="booking_slot_div">
                      Book Your Slot
                     </div>
                     
                      <div
                        className="px-1 flex flex-middle nmx-1 pb-1 "
                        style={{ borderRadius: "14px", textAlign: "end" }}
                        onClick={() => setActive(false)}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="c-pointer c-neutral-900"
                        >
                          <path
                            d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                      
                    </div>
                  
                    <div className="d-flex px-8 flex-1 flex-between flex-column" style={{marginTop:'20px'}}>
                      <div>
                        <Row>
                        <Form onSubmit={handleSubmit}>
      <div style={{ display: "flex", gap: "20px" }}>
        <div className="mb-3">
          <label htmlFor="artistName" className="form-label" style={{fontSize:'13px'}}>
            Artist Name
          </label>
          <input
            type="text"
            className="form-control"
            id="artistName"
            name="artistName"
            value={formData.artistName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label" style={{fontSize:'13px'}}>
            Mobile
          </label>
          <input
            type="number"
            className="form-control"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label" style={{fontSize:'13px'}}>
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="date-time-booking-form">
        <div className="mb-3 date-time-booking-form-firstdiv">
          <label htmlFor="date" className="form-label" style={{fontSize:'13px'}}>
            Choose Booking Date
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3" style={{ width: "100%" }}>
          <label htmlFor="time" className="form-label" style={{fontSize:'13px'}}>
            Choose Booking Time
          </label>
          <input
            className="form-control"
            id="time"
            name="time"
            type="time"
            value={formData.time}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="mb-3 text-area-requirement">
        <label htmlFor="requirements" className="form-label" style={{fontSize:'13px'}}>
          Write your requirements here...
        </label>
        <textarea
          className="form-control"
          id="requirements"
          name="requirements"
          value={formData.requirements}
          onChange={handleChange}
          rows={3}
          required
          style={{height:'100px'}}
        />
      </div>
      <div className="text-right">
        <Button type="submit" className="btn btn-primary" style={{ width: "100%",background:'#d70d57', border:'none' }} disabled={loading}>
      {
        loading ?  <Spinner animation="border" size="sm"/> : "Submit"
      }
      
          
        </Button>
      </div>
    </Form>
                        </Row>
                        {/* <div
                          className="m-0 mt-0 mb-0 ml-0 mr-0 mx-0 my-0 mt-6 margin-modalbooking"
                          style={{ height: "1px", width: "1px" }}
                        ></div>
                        <Button className="h-10 bg-black-500 hover:bg-black-500 bg-secondary-500 hover:bg-secondary-600 c-white bc-transparent c-pointer w-100p py-2 px-4 h-9 fs-4 fw-600 t-all button bs-solid tp-color td-500 bw-1 br-4 lh-solid box-border">
                          <span className="fs-3 fw-500">Get OTP</span>
                        </Button> */}
                        {/* <div
                          className="mt-5 fs-12 fw-400 c-neutral-grey ta-center"
                          style={{ marginTop: "20px" }}
                        >
                          Sell and distribute your music on all major platforms
                          worldwide.
                        </div> */}
                      </div>
                      {/* <div className="mb-3">
                            <div className="pos-r">
                            <div className="bc-grey-10 d-block bb bc-grey-10 flex-1"></div>
                            </div>
                            <div className="mt-3 d-flex flex-column flex-middle">
                            <span>
                                <span className="fs-2 c-grey-70">
                                By continuing, you agree to Studio Final Take
                                </span>
                                <span className="fs-2 fw-500 c-blue c-pointer">
                                {" "}
                                privacy policy
                                <span className="fs-2 fw-500 c-blue c-pointer">
                                    & terms of use.
                                </span>
                                </span>
                            </span>
                            <span></span>
                            </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* </div> */}
    </Modal>
  );
};

export default Booking;
