import React, { useState } from "react";
import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaCalendarAlt,
  FaHeartbeat,
  FaInstagramSquare,
  FaPhoneAlt,
  FaStar,
} from "react-icons/fa";
import { FaEarthAfrica, FaRegStarHalfStroke } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";
import BookSlot from "../../BookSlot/BookSlot";
import BookingModalForm from "../../navbar/BookingModalForm";
import Booking from "./Booking";
// import User from "./online-support.png";

const Footer = () => {
  const [active, setActive] = useState(false);
  return (
    <>
      <div>
        <button
          type="button"
          className="btn_inquiry_slot"
          onClick={() => setActive(!active)}
        >
          <FaCalendarAlt style={{ marginTop: "-2px" }} /> Book Slot
        </button>

        <div
          className="rating_bg lazyloaded"
          style={{
            position: "relative",
            backgroundImage: 'url("/Images/footer-top-banner.webp")',
          }}
        >
          <div className="overlay overlay-bold"></div>
          <Container className="rating-view">
            <Row>
              <Col xs={12} sm={6} md={4} className="p0-2">
                <div className="rat_sec1">
                  <p className="title">
                    <span>Studio Final Take</span>
                  </p>
                  <div className="rat_left">
                    <p className="rat_p">
                      <strong>4.9</strong>
                    </p>
                    <p className="fs15 osons white ttu text-center">Rating</p>
                  </div>
                  <div className="rat_right">
                    <p />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaStar color="#fde16d" size={30} />
                    <FaRegStarHalfStroke color="#fde16d" size={30} />
                    {/* <Form id="rate_frm" action="" method="POST">
                    <div className="rating-container rating-xs rating-animate">
                      <div
                        className="clear-rating clear-rating-active"
                        title="Clear"
                      >
                        <i className="fa fa-minus-circle" aria-hidden="true" />
                      </div>
                      <div className="rating">
                        <span className="empty-stars">
                          <span className="star">
                            <i className="fa fa-star-o" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star-o" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star-o" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star-o" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star-o" aria-hidden="true" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "94%" }}>
                          <span className="star">
                            <i className="fa fa-star" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star" aria-hidden="true" />
                          </span>
                          <span className="star">
                            <i className="fa fa-star" aria-hidden="true" />
                          </span>
                        </span>
                      </div>
                      <div className="caption">
                        <span className="label label-default">4.7 Stars</span>
                      </div>
                      <Form.Control
                        id="input-1"
                        name="input-1"
                        defaultValue="4.7"
                        className="hide"
                        type="hidden"
                      />
                    </div>
                  </Form> */}
                    <p />
                    {/* <p
                    className="fs12 osons weight300 white ttu mt5"
                    id="post_result"
                  >
                    Rate Us
                  </p> */}
                  </div>
                  <div className="clearfix" />
                </div>
              </Col>

              <Col xs={4} sm={6} md={2} className="p0-2">
                <div className="rat_sec2">
                  <span className="dn">
                    <strong>4.9</strong>
                  </span>
                  <p className="title2">Out of</p>
                  <p className="rat2_p">
                    <strong>5.0</strong>
                  </p>
                </div>
              </Col>

              <Col xs={4} sm={6} md={3} className="p0-2">
                <div className="rat_sec3">
                  <p className="title2">experience</p>
                  <p className="rat2_p">
                    <span className="dn">26</span>
                    <span>5+ years</span>
                  </p>
                </div>
              </Col>
              <Col xs={4} sm={6} md={3} className="p0-2">
                <div className="rat_sec4">
                  <p className="title2">Videos</p>
                  <p className="rat2_p">
                    <span className="dn">15000</span>
                    <span>500+</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <footer>
          <div className="foot2 white">
            <Container className="white-footer-second">
              <Row style={{ padding: "2rem 0px 5px 0px" }}>
                <Col xs={12} md={6} className="no_pad">
                  <div className="ft_mt35">
                    <Row>
                      <Col xs={6} sm={6} className="no_pad ten-pix-padd">
                        <p className="strong visible-sm-block visible-md-block visible-lg-block h5">
                          General Links
                        </p>
                        <div className="ft_links">
                          <Link to="/about">About Us</Link>
                          <Link to="/faq">FAQs</Link>
                          <Link to="/contact">Contact Us</Link>
                          <Link to="/comming-soon">Terms & conditions</Link>
                          {/* <Link 
                          title="Mobile App Portfolio"
                        >
                          Privacy Policy
                        </Link> */}
                          <Link to="/comming-soon">Cancellation Policy</Link>
                          {/* <Link title="SEO Portfolio">Refund Policy</Link> */}
                          <Link
                            to="https://www.studiofinaltake.com/sitemap.xml"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Sitemap
                          </Link>

                          <Link
                            target="_blank"
                            to="https://razorpay.me/@ftproduction"
                            title="Pay Online"
                          >
                            Pay Online
                          </Link>
                          <Link to="/studio-final-take-review">Reviews</Link>
                        </div>
                      </Col>
                      <Col xs={6} sm={6} md={6} className="no_pad">
                        <p className="strong visible-sm-block visible-md-block visible-lg-block h5">
                          Our Services
                        </p>

                        <div className="ft_links slide-menu">
                          <Link
                            title="Music Production"
                            to="/music-production-studio"
                          >
                            Music Production
                          </Link>
                          <Link
                            title="Sound Recording"
                            to="/sound-recording-studio"
                          >
                            Sound Recording
                          </Link>
                          <Link
                            title="Voice Over & Dubbing"
                            to="/voice-over-dubbing-studio"
                          >
                            Voice Over & Dubbing{" "}
                          </Link>
                          <Link
                            title="Mixing & Mastering"
                            to="/mixing-mastering-studio"
                          >
                            Mixing & Mastering
                          </Link>
                          <Link
                            title="Music Distribution"
                            to="https://fttunes.com/"
                            target="_blank"
                          >
                            Music Distribution
                          </Link>
                          <Link
                            title="Video Production"
                            to="/video-production-company"
                          >
                            Video Production
                          </Link>
                          <Link
                            title="Models Management"
                            to="https://www.ftmodel.in/"
                            target="_blank"
                          >
                            Models Management
                          </Link>
                        </div>
                      </Col>

                      <div className="mt10 ft"></div>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={6} className="no_pad">
                  <div className="contact_bg">
                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="contact_ft_area">
                          <Row>
                            <Col xs={4} md={12} className="p0-2">
                              <p>
                                <img
                                  src="/Images/online-support.png"
                                  
                                  alt="contact us"
                                  title="Contact us for Website Designing & Development Services in Delhi"
                                  width={77}
                                  height={75}
                                  id="contact-info"
                                />
                              </p>
                              <p className="strong h5">Contact Us</p>
                            </Col>
                            <Col xs={8} md={12} className="p0-2">
                              <div className="mt15 footer-neg-margin">
                                <p className="strong h6 whats_call">
                                  <FaPhoneAlt
                                    className="fa fa-whatsapp fa-lg"
                                    color="#d70d57"
                                  />{" "}
                                  <span>
                                    <Link
                                      to="tel:+918084187424"
                                      className="text-footer-clr"
                                    >
                                      {" "}
                                      +91 8084187424
                                    </Link>
                                  </span>
                                </p>
                                {/* <p className="strong h6">
                                  <FaPhoneAlt
                                    className="fa fa-phone fa-lg"
                                    color="#d70d57"
                                  />{" "}
                                  <span>
                                    <Link
                                      to="tel:+919625254010"
                                      className="text-footer-clr"
                                    >
                                      {" "}
                                      +91 9625254010
                                    </Link>
                                  </span>
                                </p>
                                <p className="strong h6">
                                  <FaPhoneAlt
                                    className="fa fa-phone fa-lg"
                                    color="#d70d57"
                                  />{" "}
                                  <span>
                                    <Link
                                      to="tel:+918287957870"
                                      className="text-footer-clr"
                                    >
                                      {" "}
                                      +91 8287957870
                                    </Link>
                                  </span>
                                </p> */}

                                <div className="footer-anotherdiv">
                                  <p className="strong h6">
                                    <IoMail
                                      className="fa fa-envelope-o fa-lg"
                                      color="#d70d57"
                                    />{" "}
                                    <span >
                                      <Link
                                        to="mailto:music@studiofinaltake.com"
                                        className="text-footer-clr"
                                        style={{
                                          textTransform: "lowercase",
                                         
                                        }}
                                      >
                                        {" "}
                                        music@studiofinaltake.com
                                      </Link>
                                    </span>
                                  </p>
                                  <p className="strong h6">
                                    <FaEarthAfrica
                                      className="fa fa-envelope-o fa-lg"
                                      color="#d70d57"
                                    />{" "}
                                    <span>
                                      <Link
                                        to="https://www.studiofinaltake.com/"
                                        className="text-footer-clr"
                                        target="_blank"
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {" "}
                                        Studiofinaltake.com
                                      </Link>
                                    </span>
                                  </p>
                                  <p className="strong h6">
                                    <FaEarthAfrica
                                      className="fa fa-envelope-o fa-lg"
                                      color="#d70d57"
                                    />{" "}
                                    <span>
                                      <Link
                                        to="https://www.finaltakeproduction.com/"
                                        className="text-footer-clr"
                                        target="_blank"
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {" "}
                                        finaltakeproduction.com
                                      </Link>
                                    </span>
                                  </p>
                                  <p className="strong h6">
                                    <FaEarthAfrica
                                      className="fa fa-envelope-o fa-lg"
                                      color="#d70d57"
                                    />{" "}
                                    <span>
                                      <Link
                                        target="_blank"
                                        to="https://www.ftmodel.in/"
                                        className="text-footer-clr"
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {" "}
                                        ftmodel.com
                                      </Link>
                                    </span>
                                  </p>
                                  <p className="strong h6">
                                    <FaEarthAfrica
                                      className="fa fa-envelope-o fa-lg"
                                      color="#d70d57"
                                    />{" "}
                                    <span>
                                      <Link
                                        target="_blank"
                                        to="https://fttunes.com/"
                                        className="text-footer-clr"
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {" "}
                                        fttunes.com
                                      </Link>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xs={12} sm={6}>
                        <div className="contact_ft_area">
                          <Row>
                            <Col xs={4} md={12} className="p0-2">
                              <p>
                                <img
                                  src="/Images/location-marker.png"
                                  alt="studio final take location"
                                  title="Location"
                                  width={77}
                                  height={75}
                                  loading="lazy"
                                  decoding="async"
                                  fetchpriority="low"
                                />
                              </p>
                              <p className="strong h5">
                                <span className="hidden-xs">Studio</span>{" "}
                                Location
                              </p>
                            </Col>
                            <Col xs={8} md={12} className="p0-2">
                              <div className="ft_address">
                                <address>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Nearest Metro: Dashrathpuri <br /> (Approx
                                    100 Meter Distance)
                                  </span>{" "}
                                  <br />
                                  A2, 2nd Floor Palam Dabri Road, <br /> Mahavir
                                  Enclave Dwarka,
                                  <br /> New Delhi - 110045
                                </address>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    padding: "5px 0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  LandMark - KFC/PizzaHut/DIY
                                </p>
                              </div>
                              <div className="pull-left text-center-cont">
                                <div className="soc_links">
                                  <Link
                                    to="https://www.facebook.com/finaltakefilmproduction"
                                    target="_blank"
                                    title="Facebook"
                                  >
                                    <img
                                      src="/Images/facebook.png"
                                      alt="facebook"
                                      title="Facebook"
                                      width={38}
                                      height={38}
                                      className="social-icons-style"
                                    />
                                  </Link>
                                  <Link
                                    to="https://www.x.com/Finaltakeindia"
                                    target="_blank"
                                    title="Twitter"
                                  >
                                    <img
                                      src="/Images/twitter.png"
                                      alt="twitter"
                                      title="Twitter"
                                      width={38}
                                      className="social-icons-style"
                                      height={38}
                                    />
                                  </Link>
                                  <Link
                                    to="http://www.linkedin.com/in/finaltakefilmproduction"
                                    target="_blank"
                                    title="Linkedin"
                                  >
                                    <img
                                      src="/Images/linkedin.png"
                                      alt="linkedin"
                                      title="Linkedin"
                                      width={38}
                                      height={38}
                                      className="social-icons-style"
                                    />
                                  </Link>
                                  <Link
                                    to="https://www.instagram.com/finaltakefilmproduction/"
                                    target="_blank"
                                    title="Instagram"
                                  >
                                    <img
                                      src="/Images/instagram.png"
                                      alt="instagram"
                                      title="Instagram"
                                      width={38}
                                      height={38}
                                      className="social-icons-style"
                                    />
                                    {/* <FaInstagramSquare width={36}/> */}
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="copy_rgt">
              <Container>
                <p>
                  <span>
                    <img
                      src="/Images/studio-final-take-favicon.png"
                      alt="studio final take footer logo"
                      className="footer_coppyright"
                    />
                  </span>
                  <span>
                    {" "}
                    © Copyright Studio Final Take 2024 | All rights reserved.
                    Design & Developed by <FaHeartbeat color="red" />{" "}
                    <Link
                      to="https://www.eweblink.net/"
                      target="_blank"
                      style={{
                        color: "#000093",
                        textDecoration: "none",
                        color: "#010101",
                      }}
                    >
                      eWeblink Technology
                    </Link>{" "}
                  </span>
                  {/* <Link title="Terms & Disclaimer">Terms &amp; Disclaimer</Link>{" "}
                  | <Link title="Privacy Policy">Privacy Policy</Link> */}
                </p>
              </Container>
            </div>
          </div>
        </footer>
      </div>
      {active ? <Booking active={active} setActive={setActive} /> : ""}
    </>
  );
};

export default Footer;
