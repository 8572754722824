import React, { useState } from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import "./Music.css";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import WhyStudio from "../Reusable/WhyStudio";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import CallToAction from "../Reusable/CallToAction";
import { Helmet } from "react-helmet";
const MusicProduction = () => {
  const [show, setShow] = useState(false);

  const services = [
    {
      title: "Composing",
      alt:"composing",
      image: "/Images/music-production/composing.png",
      description:
        "Studio Final Take's team of talented composers works closely with clients to create original pieces of music tailored to their needs. Whether you need a catchy jingle, an emotive soundtrack or a full-fledged album, the creative expertise is unmatched.",
     
    },
    {
      title: "Recording",
      alt:"recording",
      image: "/Images/music-production/recording.png",
      description:
        "The studio is equipped with state-of-the-art technology, providing a professional environment for recording. With a focus on capturing the best possible sound, Studio Final Take ensures that every note and nuance is perfectly recorded.",
     
    },
    {
      title: "Mixing and Mastering",
      alt:"music production",
      image: "/Images/music-production/music-production.png",
      description:
        "After recording, the mixing and mastering process is crucial in achieving a polished final product. The skilled engineers at Studio Final Take use advanced techniques to blend and refine tracks, delivering a crisp and balanced sound that meets industry standards.",
      
    },
  ];

  const points = [
    {
      title: "Comprehensive Music Production",
      description:
        "Studio Final Take stands out for its comprehensive music production services, covering every aspect from composing to mastering.",
    },
    {
      title: "Dedicated Team",
      description:
        "Whether you're an aspiring artist or a seasoned musician, our dedicated team at Studio Final Take is committed to bringing your musical vision to life.",
    },
    {
      title: "High-Quality Standards",
      description:
        "We ensure that every project meets the highest standards of quality, providing top-notch recording, mixing, and mastering services.",
    },
    {
      title: "Full Spectrum Services",
      description:
        "Studio Final Take offers a full spectrum of services including composing, recording, mixing, and mastering to cater to all your music production needs.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          Best Music Production Services in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take offers the best music production services in Delhi. With top-notch facilities and an experienced team, we deliver exceptional quality for your music production needs."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/music-production-studio"
        />
        <meta
          property="og:title"
          content="Best Music Production Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take offers the best music production services in Delhi. With top-notch facilities and an experienced team, we deliver exceptional quality for your music production needs."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/music-production-studio"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <ReusableBanner
        headone="Perfect your beats"
        headtwo="mesmerize your fans!"
         banner="/Images/banner/music-production-studio-banner.jpeg"
        para="Explore unparalleled music production at Studio Final Take, delivering the finest music production services in Delhi. Whether you're creating a new composition, recording vocals or polishing your tracks, our studio offers state-of-the-art facilities and expert producers to bring your musical vision to life. With a focus on quality and innovation, Studio Final Take ensures every project achieves its full potential. Discover why artists and musicians trust us for top-notch music production services in Delhi, where creativity meets professionalism."
      />
      <WhyStudio
        img_two="/Images/music-production-service-two.jpg"
        img_circle="/Images/testnew.png"
        head_sec="Best Music Production Services in Delhi"
        alt_one="music production service"
        alt_two="music production service"
        para_sec="Studio Final Take offers premier music production services in Delhi, known for its exceptional quality and innovation. As one of the best music production services in Delhi, we specialize in crafting unique sounds tailored to your vision. Our state-of-the-art facilities and experienced professionals ensure a seamless recording, mixing, and mastering process."
        img_one="/Images/music-production-service-one.jpg"
      />

      <ServiceSection
        heading="Best Music Production Services in Delhi"
        services={services}
      />
      <WhyUs
        heading="Discover why Studio Final Take stands out as the preferred choice for music production services in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      {/* <CommingSoon/> */}

      <div
        style={{ background: "#fbf7f778" }}
        className="place__area section-padding mt-35"
      >
        <Container>
          <Row className="align-items-center">
            <Col xl={6} lg={6} className="lg-mt-30">
              <div className="place__area-title">
                <span className="subtitle__one">Awesome Place</span>
                <h2>The Best Music Production Services in Delhi</h2>
                <p>
                  Studio Final Take has earned a reputation for providing the
                  best music production services in Delhi. The commitment to
                  quality, innovation and client satisfaction has made us a
                  preferred choice for artists across various genres. From
                  individual musicians to large bands, we cater to a diverse
                  range of musical needs with professionalism and flair.
                </p>
                {/* <button href="about" className="theme-border-btn">
                  Read More <HiOutlineArrowNarrowRight style={{ marginLeft: "10px" }} />
                </button> */}
              </div>
            </Col>
            <Col xl={6} lg={6}>
              <div className="place__area-image">
                <Row>
                  <Col>
                    <div className="place__area-image-one">
                      <img
                        src="/Images/awesome-place.jpg"
                        alt="studio final take place one"
                        fluid
                        style={{ height: "335px", objectFit: "cover" }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="place__area-image-two">
                      <img
                        src="/Images/awesome-place-two.jpg"
                        alt=" studio final take place two"
                        style={{ height: "335px", objectFit: "cover" }}
                        fluid
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CallToAction />
      {/* <CommingSoon/> */}
    </div>
  );
};

export default MusicProduction;
