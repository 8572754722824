import { Container, Row, Col } from "react-bootstrap";
import "./Contact.css";
import { FaEarthAmericas, FaLocationDot, FaPhoneVolume } from "react-icons/fa6";
import { FaAngleRight, FaPhoneAlt } from "react-icons/fa";

import { IoMail } from "react-icons/io5";
import Location from "../Home/location/Location";
import { Link } from "react-router-dom";
import { TbLocationFilled } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { BiSolidPhone } from "react-icons/bi";
import { Helmet } from "react-helmet";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
const Contact = () => {
  const[loading,setLoading]=useState(false)
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        "https://studiofinaltake.com/api/send-email",
        formData
      );

      if (response.status === 200) {
        setLoading(false)
        toast.success("Email sent successfully!");
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
      } else {
        setLoading(false)
        toast.error("Failed to send email");
      }
    } catch (error) {
      setLoading(false)
      toast.error("Error While sending the mail");
    }
  };
  return (
    <div className="section pt-12 mb-10">
      <Helmet>
        <title>
          Contact Studio Final Take: Premier Music Recording Studio in Delhi
        </title>
        <meta
          name="description"
          content="Contact Studio Final Take, the premier music recording studio in Delhi, for top-notch music production services."
        />
        <link rel="canonical" href="https://www.studiofinaltake.com/contact" />
        <meta
          property="og:title"
          content="Contact Studio Final Take: Premier Music Recording Studio in Delhi"
        />
        <meta
          property="og:description"
          content="Contact Studio Final Take, the premier music recording studio in Delhi, for top-notch music production services."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/contact"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta name="keywords" content="music recording studio in delhi" />
        <meta name="keywords" content="mixing and mastering studio in delhi" />
        <meta name="keywords" content="best music recording studio" />
        <meta name="keywords" content="mastering studio in new delhi" />
        <meta name="keywords" content="recording studio in south west delhi" />
        <meta name="keywords" content="song recording studio in new delhi" />
      </Helmet>
      <div className="parent-contact">
        <div
          className="about-sec-one-img"
          style={{ background: "url(/Images/Guitars.jpg)" }}
        >
          <div className="overlay overlay-bold overlay-about"></div>
          <div className="about-sec-one-main">
            <h1 className="about-headone">Contact Us</h1>
            <h6 className="about-sex-headsix">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                {" "}
                Home
              </Link>{" "}
              <FaAngleRight /> Contact
            </h6>
          </div>
        </div>
      </div>
      <Container style={{ marginTop: "100px" }}>
        <Row>
          <Col
            md={8}
            className="contact-text-box-sec"
            style={{ padding: "25px" }}
          >
            <h3
              style={{ textAlign: "start" }}
              className="section-title bottom-line mb-5"
            >
              Get In Touch
            </h3>
            <form className="contact-form" onSubmit={handleSubmit}>
              <Row>
                <Col md={4}>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mb-3 contact_us_form"
                    placeholder="Name"
                  />
                </Col>
                <Col md={4}>
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="mb-3 contact_us_form"
                    placeholder="Phone Number"
                  />
                </Col>
                <Col md={4}>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mb-3 contact_us_form"
                    placeholder="Email"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="mb-3 contact_us_form"
                    placeholder="Message"
                    rows={15}
                 
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <button type="submit" className="button-contactt" disabled={loading}>
                    {
                      loading ?  <Spinner animation="border" size="sm"/> : "SEND US NOW"
                    }
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
          <Col md={4}>
            <div className="contact-text-box text-dark">
              <Link to="https://www.maps.app.goo.gl/4ftPgrEtrWh5cbDw8">
                <TbLocationFilled size={25} color="#dc2751" />
                <h3>Studio Location</h3>
                <p>
                  <span style={{ fontWeight: "600" }}>
                    Nearest Metro: Dashrathpuri (Approx100 Meter Distance)
                  </span>{" "}
                  <br />
                  A2, 2nd Floor Palam Dabri Road, <br /> Mahavir Enclave Dwarka,{" "}
                  <br /> New Delhi - 110045
                </p>
              </Link>

              <p style={{ fontWeight: "600" }}>LandMark - KFC/PizzaHut/DIY</p>
            </div>
            <div className="contact-text-box text-dark">
              <Link to="tel:+918084187424">
                <FaPhoneVolume size={25} color="#dc2751" />
                <h3>Give us a call or whatsapp</h3>
                <p>(+91) 8084187424</p>
                <p>
                  (+91) 9625254010 <br />
                  (+91) 8287957870
                </p>
              </Link>
            </div>
            <div className="contact-text-box text-dark">
              <Link to="#">
                <MdEmail size={27} color="#dc2751" />
                <h3>Write Us</h3>
              </Link>
              <p>
                <Link to="#"></Link>
                <Link to="mailto:music@studiofinaltake.com">
                  music@studiofinaltake.com
                </Link>
              </p>
              <p>
                <Link to="mailto:info@finaltakeproduction.com">
                  info@finaltakeproduction.com
                </Link>{" "}
              </p>
            </div>
            <div className="contact-text-box text-dark">
              <Link to="#">
                <FaEarthAmericas size={27} color="#dc2751" />
                <h3>Our Websites</h3>
              </Link>
              <p>
                <Link to="https://www.studiofinaltake.com/" target="_blank">
                  www.studiofinaltake.com
                </Link>
              </p>
              <p>
                <Link to="https://www.finaltakeproduction.com" target="_blank">
                  www.finaltakeproduction.com
                </Link>{" "}
              </p>
              <p>
                <Link to="https://www.ftmodel.in/" target="_blank">
                  www.ftmodel.in
                </Link>{" "}
              </p>
              <p>
                <Link to="https://www.fttunes.com/" target="_blank">
                  www.fttunes.com
                </Link>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Location />
    </div>
  );
};

export default Contact;
