import React from "react";
import ReusableBanner from "../Reusable/ReusableBanner";
import CommingSoon from "../../CommingSoon";
import WhyStudio from "../Reusable/WhyStudio";
import CallToAction from "../Reusable/CallToAction";
import ServiceSection from "../Reusable/ServiceSection";
import WhyUs from "../Reusable/WhyUs";
import { Helmet } from "react-helmet";
const VoiceOver = () => {
  const services = [
    {
      title: "Commercial Voice Overs",
      alt:"commercial voice overs",
      image: "/Images/voice-over-dubbing/commercial-voice-overs.png",
      description:
        "Studio Final Take offers top-tier commercial voice over services in Delhi, ensuring your brand's message is delivered with clarity and impact. Our professional voice artists bring your commercials to life, providing engaging and persuasive narrations tailored to your specific needs.",
     
    },
    {
      title: "Narration Services",
      image: "/Images/voice-over-dubbing/narration-services.png",
      alt:"narration services",
      description:
        "Studio Final Take offers professional narration video dubbing services in Delhi, enhancing your storytelling with captivating voiceovers. Our skilled narrators bring scripts to life, delivering clear and emotive narrations that engage audiences.",
     
    },
    {
      title: "Film and TV Dubbing",
      image: "/Images/voice-over-dubbing/film-and-tv-dubbing.png",
      alt:"film and tv dubbing",
      description:
        "Studio Final Take offers premier film and TV dubbing services in Delhi, ensuring top-notch quality and professionalism. Our state-of-the-art facilities and experienced team deliver seamless dubbing solutions for various media projects. Whether it's dubbing for films, TV shows, documentaries or commercials, we excel in capturing the essence of every scene with precision and clarity.",
      
    },
    {
      title: "Corporate Video Dubbing",
      image: "/Images/voice-over-dubbing/corporate-video-dubbing.png",
      alt:"corporate video dubbing",
      description:
        "Studio Final Take offers exceptional corporate video dubbing services in Delhi, ensuring your business communications reach a global audience effectively. Our experienced dubbing artists provide clear and professional voice overs, perfectly aligned with the original content.",
     
    },
    {
      title: "Web Series Dubbing",
      image: "/Images/voice-over-dubbing/web-series-dubbing.png",
      alt:"web series dubbing",
      description:
        "Studio Final Take specializes in web series dubbing services in Delhi, transforming your content for global audiences. Our skilled dubbing artists deliver precise and engaging performances, ensuring your web series retains its original charm and emotional depth.",
    
    },
  ];

  const points = [
    {
      title: "Professional Talent Pool",
      description:
        "Our team comprises highly skilled voice actors and dubbing artists with extensive experience in the industry. Each artist brings a unique flair to their work, ensuring a perfect match for your project's needs.",
    },
    {
      title: "Advanced Technology",
      description:
        "Our studios are equipped with cutting-edge recording and editing equipment, ensuring the highest audio quality. We utilize industry-standard software to deliver pristine sound, free from background noise and distortions.",
    },
    {
      title: "Customized Solutions",
      description:
        "We understand that every client need is different. That's why we offer customized voice over and dubbing solutions tailored to your specific requirements. Whether you need a particular accent, tone or style, we have the expertise to deliver.",
    },
    {
      title: "Quick Turnaround Times",
      description:
        "We value your time and strive to complete projects within the agreed timelines without compromising on quality. Our efficient workflow ensures that you receive your final product promptly.",
    },
    {
      title: "Competitive Pricing",
      description:
        "Studio Final Take offers premium voice over and dubbing services at competitive prices. We believe in providing exceptional value, ensuring that you get the best return on your investment.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Best Voice Over & Dubbing Services in Delhi | Studio Final Take
        </title>
        <meta
          name="description"
          content="Studio Final Take offers the best voice over & dubbing services in Delhi. Our professional team ensure top-quality voice over & dubbing services in Delhi."
        />
        <link
          rel="canonical"
          href="https://www.studiofinaltake.com/voice-over-dubbing-studio"
        />
        <meta
          property="og:title"
          content=" Best Voice Over & Dubbing Services in Delhi | Studio Final Take"
        />
        <meta
          property="og:description"
          content="Studio Final Take offers the best voice over & dubbing services in Delhi. Our professional team ensure top-quality voice over & dubbing services in Delhi."
        />
        <meta
          property="og:url"
          content="https://www.studiofinaltake.com/voice-over-dubbing-studio"
        />
        <meta property="og:site_name" content="STUDIO FINAL TAKE" />
        <meta property="og:type" content="Local Business" />
        <meta
          name="keywords"
          content="music recording studio in delhi, mixing and mastering studio in delhi, best music recording studio, mastering studio in new delhi, recording studio in south west delhi, song recording studio in new delhi"
        />
      </Helmet>
      <ReusableBanner
        headone="Refine your voice"
        headtwo="captivate your audience!"
         banner="/Images/banner/voice-over-dubbing-studio-banner.jpeg"
        para="At Studio Final Take, we pride ourselves on offering the best voice over & dubbing services in Delhi. Our state-of-the-art facilities and experienced team ensure that every project we undertake meets the highest standards of quality and professionalism."
      />
      <WhyStudio
        img_two="/Images/voice-over-dubbing-service-two.jpg"
        alt_one="voice over dubbing service "
        alt_two="voice over dubbing service"
        img_circle="/Images/testnew.png"
        head_sec="Best Voice Over & Dubbing Services in Delhi"
        para_sec="At Studio Final Take, we pride ourselves on offering the best voice over & dubbing services in Delhi. Our state-of-the-art facilities and experienced team ensure that every project we undertake meets the highest standards of quality and professionalism."
        img_one="/Images/voice-over-dubbing-service-one.jpg"
      />
      <ServiceSection
        heading="Best Voice Over & Dubbing Services in Delhi"
        services={services}
      />
      <WhyUs
        heading="Discover why Studio Final Take stands out as the preferred choice for voice over services in Delhi:"
        points={points}
        image="https://www.templates.hibootstrap.com/atoli/default/assets/img/faq/faq-img3.jpg "
      />
      {/* <CommingSoon/> */}

      <CallToAction />
    </div>
  );
};

export default VoiceOver;
